<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-if="moduleId == 11 || moduleId == 25"
          variant="success"
          @click="openCreateApplicationModal"
        >
          <feather-icon icon="PlusIcon" />
          Create
        </b-button>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'application-pending-specialist-digital' }"
        exact
        :exact-active-class="statusApp == 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >IN PROCESS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'application-completed-specialist-digital' }"
        exact
        :exact-active-class="statusApp == 2 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >COMPLETED
      </b-nav-item>
      <b-nav-item
        v-if="moduleId == 11 || moduleId == 25"
        :to="{ name: 'application-deleted-specialist-digital' }"
        exact
        :exact-active-class="statusApp == 3 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >DELETED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="key" :program_id="0" />
    </b-card>
    <create-application-modal
      v-if="ActiveCreateApplicationModal"
      @close="closeCreateApplicationModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import TableApplications from "@/views/commons/components/applications/views/TableAplications.vue";
import CreateApplicationModal from "@/views/commons/components/applications/views/components/modals/CreateApplicationModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    CreateApplicationModal,
  },
  data() {
    return {
      ActiveCreateApplicationModal: false,
      table: null,
      key: 0,
    };
  },
  methods: {
    refreshTable() {
      this.key++;
    },
    openCreateApplicationModal() {
      this.ActiveCreateApplicationModal = true;
    },
    closeCreateApplicationModal() {
      this.ActiveCreateApplicationModal = false;
    },
    ...mapActions({
      getCountriesAction: "DebtSolutionApplications/getCountriesAction",
    }),
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
  },
  watch: {
    "$route.name": function () {
      this.key++;
    },
  },
  created() {
    this.getCountriesAction();
  },
};
</script>
<style>
</style>
